import(/* webpackMode: "eager" */ "/root/repo/client/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/root/repo/client/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/root/repo/client/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/root/repo/client/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/root/repo/client/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/RobotoCondensed-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/RobotoCondensed-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/RobotoCondensed-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/RobotoCondensed-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/RobotoCondensed-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/global/consent-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/global/icon-defs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/primitives/toast-provider.tsx");
;
import(/* webpackMode: "eager" */ "/root/repo/client/src/styles/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/utils/init-time-ago.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/utils/providers/clerk.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CooldownProvider"] */ "/root/repo/client/src/utils/providers/cooldown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OddsFormatProvider"] */ "/root/repo/client/src/utils/providers/odds-format.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/utils/providers/progress.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScoreboardProvider"] */ "/root/repo/client/src/utils/providers/scoreboard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScoresProvider"] */ "/root/repo/client/src/utils/providers/scores.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SettingsProvider"] */ "/root/repo/client/src/utils/providers/settings.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SportsbookProvider"] */ "/root/repo/client/src/utils/providers/sportsbook.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebSocketProvider"] */ "/root/repo/client/src/utils/providers/websocket.tsx");
